.toggles {
  margin-top: 20px;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toggle {
    background: rgba(36, 37, 38, 0.5);
    border: 1px solid #49494d;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 30px;

    @media (min-width: 576px) {
      width: calc(50% - 10px);
    }

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:not(:last-child) {
      @media (max-width: 575px) {
        margin-bottom: 20px;
      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        flex-direction: column;
      }

      @media (min-width: 992px) {
        margin-bottom: 0;
      }

      .icon {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        svg path {
          fill: white;
        }
      }

      .label {
        line-height: 1;
        color: white;
        margin-top: 15px;
        font-size: 18px;

        @media (min-width: 576px) {
          margin-left: 15px;
          margin-top: 0;
        }
      }
    }

    .right {
      text-align: center;
    }
  }
}
