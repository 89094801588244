.nodes {
  background: rgba(36, 37, 38, 0.5);
  border: 1px solid #49494d;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-top: 60px;

  .heading {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      line-height: 1;
      font-size: 18px;
      font-weight: bold;
      color: #808ea3;
    }

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: rgba(white, 0.1);
      border-radius: 6px;
      padding: 10px 15px;
      font-size: 14px;
      color: white;
      border: none;

      @media (min-width: 576px) {
        width: 130px;
      }

      &.disabled {
        pointer-events: none;
        color: rgba(white, 0.5);
        background-color: #303234 !important;

        svg path {
          fill: rgba(white, 0.5);
        }
      }

      span {
        display: none;

        @media (min-width: 576px) {
          display: inline;
          margin-left: 10px;
        }
      }

      svg path {
        fill: white;
      }
    }
  }

  .body {
    border-top: 1px solid #49494d;
    padding: 30px;
    color: white;

    .server {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid #49494d;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #808ea3;
      margin: 15px auto 20px;
    }

    .message {
      margin: 0 auto;
      line-height: 1;
      font-size: 18px;
      text-align: center;
      color: #808ea3;
      margin-bottom: 15px;
    }

    .node {
      align-items: center;
      justify-content: space-between;
      display: flex;
      background: #242526;
      border: 1px solid #49494d;
      border-radius: 8px;
      padding: 15px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .left {
        display: flex;
        align-items: center;

        @media (min-width: 576px) {
          width: 50%;
        }

        .icon {
          flex-shrink: 0;
          width: 45px;
          height: 45px;
          justify-content: center;
          display: flex;
          align-items: center;
          border: 1px solid #49494d;
          border-radius: 50%;
          background: #303234;

          @media (max-width: 575px) {
            display: none;
          }
        }

        .link {
          max-width: calc(100% - 60px);
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #808ea3 !important;

          @media (min-width: 576px) {
            margin-left: 15px;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .code {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          padding-right: 10px;

          @media (max-width: 575px) {
            display: none;
          }
        }
      }

      .right {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        @media (min-width: 576px) {
          flex-grow: 0;
          width: 50%;
        }

        .status {
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 14px;
          flex-grow: 1;
          line-height: 1;

          @media (min-width: 576px) {
            flex-grow: 0;
          }

          @media (min-width: 992px) {
            min-width: 180px;
            padding: 0 45px;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-style: solid;
            border-width: 1px;
            border-radius: 50%;
            margin-right: 10px;

            @media (max-width: 575px) {
              display: none;
            }
          }

          &.danger {
            color: #f53855;
            border-color: #f53855;

            svg path {
              fill: #f53855;
            }
          }

          &.warning {
            color: orange;
            border-color: orange;

            svg path {
              fill: orange;
            }
          }

          &.success {
            color: #23d389;
            border-color: #23d389;

            svg path {
              fill: #23d389;
            }
          }
        }

        .toggle {
          background: #303234;
          border-radius: 6px;
          color: white;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          line-height: 1;

          &.disabled {
            pointer-events: none;
            color: rgba(white, 0.5) !important;
            background-color: #303234 !important;
          }

          @media (min-width: 768px) {
            padding: 15px 25px;
          }

          @media (max-width: 767px) {
            span {
              display: none;
            }
          }

          .angle {
            display: inline;

            @media (min-width: 768px) {
              margin-left: 15px;
            }

            @media (min-width: 992px) {
              margin-left: 45px;
            }
          }

          &[aria-expanded='true'] .angle {
            transform: rotateZ(180deg);
          }

          &:after {
            display: none;
          }
        }

        .menu {
          background: #303234;
          box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
          border-radius: 6px;
          width: 100%;
          padding: 8px;

          &[data-popper-placement='bottom-start'] {
            margin-top: 5px !important;
          }

          &[data-popper-placement='top-start'] {
            margin-bottom: 5px !important;
          }

          .action {
            padding: 10px 15px;
            border-radius: 4px;
            font-size: 14px;
            line-height: 1;
            color: white !important;

            &.disabled {
              pointer-events: none;
              color: rgba(white, 0.5) !important;
            }

            &:hover {
              background: #424447;
            }
          }
        }
      }
    }
  }
}
