.withdrawals {
  background: rgba(36, 37, 38, 0.5);
  border: 1px solid #49494d;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-top: 60px;

  .heading {
    padding: 30px;

    .title {
      line-height: 1;
      font-size: 18px;
      font-weight: bold;
      color: #808ea3;
    }
  }

  .body {
    border-top: 1px solid #49494d;
    padding: 30px;
    color: white;
  }
}
